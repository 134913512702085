import React, { useState, useEffect} from "react";
import { Container } from "shards-react";
import PaymentExperienceService from "../../../flux/services/PaymentExperienceService";
import ScoreService from "../../../flux/services/ScoreService";
import SummaryService from "../../../flux/services/SummaryService";
import searchStore from "../../../flux/stores/searchStore";
import loginStore from "../../../flux/stores/loginStore";
import CustomerGraph from "./CustomerGraph";
import { Row } from "shards-react";
import PlacedForCollectionsService from "../../../flux/services/PlacedForCollectionsService";
import NSFCheckService from "../../../flux/services/NSFCheckService";

export default function TradeReportOriginal(props)
{
    let customerRecord = searchStore.getCustomerRecord();
    let user = loginStore.getUserName();
    
    //alert(JSON.stringify(customerRecord))

    const [data, setData] = useState({
        paymentExperience: null,
        summary: null,
        trends: null,
        scores: null,
        history: null,
        pfc: null,
        nsf: null
    });

    useEffect(() => {
        async function getTradeReport(accountId)
        {
    
          try {
            // set loading to true before calling API
            const paymentExperience = await PaymentExperienceService.getPaymentExperience(accountId);
            const summary = await SummaryService.getSummary(accountId);
            const trends = await SummaryService.getTrends(accountId);
            const scores = await ScoreService.getScores(accountId);
            const history = await ScoreService.getScoreHistory(accountId);
            const pfc = await PlacedForCollectionsService.getPFC(accountId);
            const nsf = await NSFCheckService.getNSFChecks(accountId);

            setData({
                paymentExperience: paymentExperience,
                summary: summary,
                trends: trends,
                scores: scores,
                history: history,
                pfc: pfc,
                nsf: nsf
            });
          } catch (error) {
            // add error handling here
            console.log(error);
          }
        }
        getTradeReport(props.accountId);
      
        }, []);

    const PaymentExperienceRows = (props) =>
    {
        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0,
          });
      
          const {member_id,date_reported,date_last_sale,terms,recent_high_credit,account_balance,current_balance,one_day_past_due_amt, thirty_day_past_due_amt, sixty_day_past_due_amt, ninety_day_past_due_amt, number_days_slow, payment_history, pct_current,pct_one_day_past_due, pct_thirty_day_past_due, pct_sixty_day_past_due, pct_ninety_day_past_due} = props
          let dateReported = date_reported.toString().substring(4,6) + '/' + date_reported.toString().substring(6,8) + '/' + date_reported.toString().substring(2,4);
          let lastSale = date_last_sale.toString().substring(4,6) + '/' + date_last_sale.toString().substring(2,4);
          let paymentHistory = "";
          if (payment_history == "A/S" || payment_history == "P/S" || payment_history == "D/S" || payment_history == "Slo")
            paymentHistory = payment_history + " " + number_days_slow;
          else 
            paymentHistory = payment_history;
          //alert(recent_high_credit);
          return ( <tr className="style1">
            <td>{member_id}</td>
            <td>{dateReported}</td>
            <td>{(terms == 'CBD' || terms == 'CIA' || terms == 'COD')?terms:""}</td>
            <td>{lastSale}</td>
            <td>{paymentHistory}</td>
            <td><div align="right">{recent_high_credit}</div></td>
            <td><div align="right">{account_balance}</div></td>
            <td><div align="right">{pct_current=="0"?"":pct_current}</div></td>
            <td><div align="right">{pct_one_day_past_due=="0"?"":pct_one_day_past_due}</div></td>
            <td><div align="right">{pct_thirty_day_past_due=="0"?"":pct_thirty_day_past_due}</div></td>
            <td><div align="right">{pct_sixty_day_past_due=="0"?"":pct_sixty_day_past_due}</div></td>
            <td><div align="right">{pct_ninety_day_past_due=="0"?"":pct_ninety_day_past_due}</div></td>
          </tr>
          )
    }

    const PaymentExperienceTable = (props) =>
    {
        //alert(JSON.stringify(data));
        if (!data.paymentExperience) {
            return (
                <span>Data not available</span>
            );
        }

        return(
            <table width="712" border="0" align="center" cellpadding="0">
                <tr className="style1">
                    <td width="54" height="16">Mem ID</td>
                    <td width="78">Date Reptd</td>
                    <td width="34">&nbsp;</td>
                    <td width="87">Last Sales </td>
                    <td width="68">Pay Hist </td>
                    <td width="85"><div align="right">High Credit</div></td>
                    <td width="67"><div align="right">$Bal</div></td>
                    <td width="60"><div align="right">%Cur</div></td>
                    <td width="36"><div align="right">1-30</div></td>
                    <td width="39"><div align="right">31-60</div></td>
                    <td width="42"><div align="right">61-90</div></td>
                    <td width="36"><div align="right">91+</div></td>
                </tr>
                {data.paymentExperience.map((data) => (
                <PaymentExperienceRows key={data.credit_info_id} member_id={data.member_id} date_reported={data.extract_date_id} date_last_sale={data.date_last_sale_id} terms={data.terms} recent_high_credit={data.recent_high_credit} account_balance={data.account_balance} current_balance={data.current_balance}
                      one_day_past_due_amt={data.one_day_past_due_amt} thirty_day_past_due_amt={data.thirty_day_past_due_amt} sixty_day_past_due_amt={data.sixty_day_past_due_amt}
                      ninety_day_past_due_amt={data.ninety_day_past_due_amt} number_days_slow={data.number_days_slow} payment_history={data.payment_history} pct_current={data.pct_current}
                      pct_one_day_past_due={data.pct_one_day_past_due} pct_thirty_day_past_due={data.pct_thirty_day_past_due} pct_sixty_day_past_due={data.pct_sixty_day_past_due}
                      pct_ninety_day_past_due={data.pct_ninety_day_past_due} /> 
              ))}
            </table>
        )

    }

    const SummaryPctRows = (props) => {

        const {pct_current,pct_one_day_past_due, pct_thirty_day_past_due, pct_sixty_day_past_due, pct_ninety_day_past_due} = props
    
        return ( <tr className="style1">
          <td>--------</td>
          <td>--------</td>
          <td>{pct_current=="0"?"":pct_current}</td>
          <td><div align="right">{pct_one_day_past_due=="0"?"":pct_one_day_past_due}</div></td>
          <td><div align="right">{pct_thirty_day_past_due=="0"?"":pct_thirty_day_past_due}</div></td>
          <td><div align="right">{pct_sixty_day_past_due=="0"?"":pct_sixty_day_past_due}</div></td>
          <td><div align="right">{pct_ninety_day_past_due=="0"?"":pct_ninety_day_past_due}</div></td>
        </tr>
        )
      }
    const SummaryRows = (props) => {
  
      const {report_date_id,recent_high_credit,account_balance,current_balance,one_day_past_due_amt, thirty_day_past_due_amt, sixty_day_past_due_amt, ninety_day_past_due_amt} = props
  
      return ( <tr className="style1">
        <td>{recent_high_credit}</td>
        <td>{account_balance}</td>
        <td>{current_balance=="0"?"":current_balance}</td>
        <td><div align="right">{one_day_past_due_amt=="0"?"":one_day_past_due_amt}</div></td>
        <td><div align="right">{thirty_day_past_due_amt=="0"?"":thirty_day_past_due_amt}</div></td>
        <td><div align="right">{sixty_day_past_due_amt=="0"?"":sixty_day_past_due_amt}</div></td>
        <td><div align="right">{ninety_day_past_due_amt=="0"?"":ninety_day_past_due_amt}</div></td>
      </tr>
      )
    }
  
    const SummaryTable = (props) => {
      
      //const {data} = props
      //alert(JSON.stringify(data))
      if (!data.summary) return (
        <span>Data not available</span>
      );
  
      return (
              <Row className="px-5">
                <table width="712" border="0" align="center" cellpadding="0">
                    <tr className="style1">
                    <th width="110">High Credit</th>
                    <th width="104">Balance</th>
                    <th width="97">%Current</th>
                    <th width="61"><div align="right">1-30</div></th>
                    <th width="71"><div align="right">31-60</div></th>
                    <th width="67"><div align="right">61-90</div></th>
                    <th width="49"><div align="right">90+</div></th>
                    </tr>
                <tbody> 
                  
  
  
                {data.summary.map((data) => (
                  <SummaryRows key={data.credit_summary_id} report_date_id={data.report_date_id} recent_high_credit={data.recent_high_credit} account_balance={data.account_balance} current_balance={data.current_balance} one_day_past_due_amt={data.one_day_past_due_amt} 
                        thirty_day_past_due_amt={data.thirty_day_past_due_amt} sixty_day_past_due_amt={data.sixty_day_past_due_amt}
                        ninety_day_past_due_amt={data.ninety_day_past_due_amt} />
                ))}
  
                {data.summary.map((data) => (
                  <SummaryPctRows key={data.credit_summary_id} pct_current={data.pct_current}
                        pct_one_day_past_due={data.pct_one_day_past_due} pct_thirty_day_past_due={data.pct_thirty_day_past_due} pct_sixty_day_past_due={data.pct_sixty_day_past_due}
                        pct_ninety_day_past_due={data.pct_ninety_day_past_due} />
                ))}
  
                </tbody>
                </table>
              </Row>)
    }

    // Implement payment Trends table
    const TrendRows = (props) => {
        const {report_date_id,recent_high_credit,account_balance,current_balance,one_day_past_due_amt, thirty_day_past_due_amt, sixty_day_past_due_amt, ninety_day_past_due_amt, pct_current,pct_one_day_past_due, pct_thirty_day_past_due, pct_sixty_day_past_due, pct_ninety_day_past_due} = props
        let asOfDate = report_date_id.toString().substring(4,6) + '/' + report_date_id.toString().substring(6,8) + '/' + report_date_id.toString().substring(2,4);
        let USDollar = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0, 
          minimumFractionDigits: 0,
        });
    
        return ( 
        <tr class="style1">
          <td>{asOfDate}</td>
          <td>{recent_high_credit}</td>
          <td>{account_balance}</td>
          <td><div align="right">{pct_current=="0"?"":pct_current}</div></td>
          <td><div align="right">{pct_one_day_past_due=="0"?"":pct_one_day_past_due}</div></td>
          <td><div align="right">{pct_thirty_day_past_due=="0"?"":pct_thirty_day_past_due}</div></td>
          <td><div align="right">{pct_sixty_day_past_due=="0"?"":pct_sixty_day_past_due}</div></td>
          <td><div align="right">{pct_ninety_day_past_due=="0"?"":pct_ninety_day_past_due}</div></td>
        </tr>
        )
      }
   
      const TrendTable = (props) => {
        
        if (!data.trends) return (
          <span>Data not available</span>
        );
        //alert(JSON.stringify(data))]
        return (
                <Row className="px-5">
                  <table border="0" align="center" cellpadding="0">
                    <tr className="style1">
                                               
                        <th>As Of Date</th>
                        <th>High Credit</th>
                        <th>Balance</th>
                        <th><div align="right">Current</div></th>
                        <th><div align="right">1-30</div></th>
                        <th><div align="right">31-60</div></th>
                        <th><div align="right">61-90</div></th>
                        <th><div align="right">90+</div></th>
                    </tr>
     
                    <tbody> 
                  
                      {data.trends.map((data) => (
                          <TrendRows key={data.credit_summary_id} report_date_id={data.report_date_id} recent_high_credit={data.recent_high_credit} account_balance={data.account_balance} current_balance={data.current_balance} one_day_past_due_amt={data.one_day_past_due_amt} 
                                thirty_day_past_due_amt={data.thirty_day_past_due_amt} sixty_day_past_due_amt={data.sixty_day_past_due_amt}
                                ninety_day_past_due_amt={data.ninety_day_past_due_amt}  pct_current={data.pct_current}
                                pct_one_day_past_due={data.pct_one_day_past_due} pct_thirty_day_past_due={data.pct_thirty_day_past_due} pct_sixty_day_past_due={data.pct_sixty_day_past_due}
                                pct_ninety_day_past_due={data.pct_ninety_day_past_due}/>
                        ))
                      }
                    </tbody>
    
    
                      
    
                  </table>
                </Row>
        )
      }

      // Implement table for Scores
      const ScoreRows = (props) => {
        const {date_id, account_id, score_type, amount} = props
        return ( 
          <tr className="style1">
          <td></td>
          <td></td>
          <td>Current {score_type}:</td>
          <td>{amount}</td>
          <td></td>
          <td></td>
        </tr>
        )
      }
    
    const ScoreTable = (props) => {
        //alert(JSON.stringify(data.scores))
        if (!data.scores) return (
          <span>Data not available</span>
        );
        return (
              <Row className="px-5">
                <table width="575" border="0" cellpadding="0">
                    <tr className="style1">
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th></th>
                    <th></th>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                <tbody> 
                  
                  {data.scores.map((data) => (
                      <ScoreRows key={data.scores_id} date_id={data.date_id} account_id={data.account_id} score_type={data.attribute_value} amount={data.amount}  />
                    ))
                  }
    
                </tbody>
                </table>
              </Row>)
      }

      const PlacedForCollectionsRows = (props) => {
        const {date_entered,account_id,member_id,amount} = props
        let dateEntered = date_entered.toString().substring(5,7) + '/' + date_entered.toString().substring(8,10) + '/' + date_entered.toString().substring(0,4)
        let USDollar = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0, 
          minimumFractionDigits: 0,
        });
    
        return ( <tr className="style1">
          <td>{member_id}</td>
          <td>{dateEntered}</td>
          <td>{amount}</td>
        </tr>
        )
      }
      const PlacedForCollections = (props) => {
        if (!data.pfc || data.pfc.length == 0)
          return (
            <div></div>
          )
        
        return (
          <div>
          <hr></hr>
          <div align="center" class="style4">Placed for Collections</div>
          <div style={{height: "20px"}}></div>
          <Row className="px-5">
            <table width="575" border="0" cellpadding="0">
                <tr className="style1">
                
                <th>Member Id</th>
                <th>Date Reported</th>
                <th>PFC Amount</th>

                </tr>
              
              {data.pfc.map((data) => (
                <PlacedForCollectionsRows date_entered={data.date_entered} account_id={data.account_id} member_id={data.member_id} amount={data.amount}/>
              ))}

            </table>
          </Row>
          </div>
          )
      }

      const NSFRows = (props) => {
        const {profile, nsf_check_id, account_id,amount,times_returned,member_id,date_entered} = props
        let dateEntered = date_entered.toString().substring(5,7) + '/' + date_entered.toString().substring(8,10) + '/' + date_entered.toString().substring(0,4)
    
        return ( <tr className="style1">
          <td>{member_id}</td>
          <td>{dateEntered}</td>
          <td>{amount}</td>
          <td>{times_returned}</td>
          <td></td>
        </tr>
        )
      }

      const NSF = (props) => {
        if (!data.nsf || data.nsf.length == 0)
          return (
            <div></div>
          )
        return (
          <div>
          <hr></hr>
          <div align="center" class="style4">NSF Payments</div>
          <div style={{height: "20px"}}></div>
          <Row className="px-5">
              <table width="575" border="0" cellpadding="0">
                  <tr className="style1">
                  
                  <th>Member Id</th>
                  <th>Date Entered</th>
                  <th>Amt Returned</th>
                  <th># Times Returned</th>
                  <th></th>
                  </tr>
              <tbody> 
                
                {data.nsf.map((data) => (
                  <NSFRows key={data.nsf_check_id} nsf_check_id={data.nsf_check_id} account_id={data.account_id} amount={data.amount} times_returned={data.times_returned}
                        member_id={data.member_id} date_entered={data.date_entered}/>
                ))}
  
              </tbody>
              </table>
          </Row>
          </div>
  
          )
      }
    
      const HistoryRows = (props) => {
        const {date_id, account_id, score_type, amount} = props
        let asOfDate = date_id.toString().substring(4,6) + '/' + date_id.toString().substring(6,8) + '/' + date_id.toString().substring(2,4);
    
    
        return ( 
          <tr className="style1">
          <td></td>
          <td></td>
          <td>{asOfDate.substring(0,3) + asOfDate.substring(6,8)}</td>
          <td></td>
          <td>{amount}</td>
          <td></td>
          <td></td>
        </tr>
        )
      }
          
      const HistoryTable = (props) => {
        
        if (!data.history) return (
          <span>Data not available</span>
        );
        return (
            <Row className="px-5">
                <table width="575" border="0" cellpadding="0">
                    <tr className="style1">
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th>Report Date</th>
                    <th width="100"></th>
                    <th>CCI Score</th>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                <tbody> 
                  
                {data.history.map((data) => (
                      <HistoryRows key={data.scores_id} date_id={data.date_id} account_id={data.account_id} score_type={data.attribute.attribute_value} amount={data.amount}  />
                    ))
                }
    
                </tbody>
                </table>
              </Row>)
      }
    const PFCNote = (props) => {
      if (!data.pfc || data.pfc.length == 0)
        return (
          <div></div>
          )

      return (
      
        <div align="center" class="style3">Attention! PFC activity in this account</div>
      )
    }
    const NSFNote = (props) => {
      if (!data.nsf || data.nsf.length == 0)
        return (
          <div></div>
          )
      return (
      
        <div align="center" class="style3">Attention! NSF activity in this account within past 12 months</div>
      )
    }

    if (!data)
        return (
            <Row>

            </Row>
        )
    return (
        <div class='container-fluid'>
            
            <div align="center" className="style2">Round Table Floor Covering Credit Group</div>
            <div align="center" class="style3">Trade Payment Report</div>
            <hr classname="black"></hr>
            <table width="611" border="0" align="center" cellpadding="0">
            <tr className="style1">
                <td width="312" height="19">{(customerRecord[0].account_name).trim()}<br /></td>
                <td width="312">CCI CustomerID: {customerRecord[0].cci_account_id}</td>
            </tr>
            <tr className="style1">
                <td valign="top">-------------------</td>
                <td> </td>
            </tr>
            <tr className="style1">
                <td>{customerRecord[0].address1.trim()}</td>
                <td>File Created: {(customerRecord[0].created_date)?customerRecord[0].created_date.substring(0,10):""}</td>
            </tr>
            <tr className="style1">
                <td>{customerRecord[0].address2 != null?customerRecord[0].address2.trim():""}</td>
                <td>Report Date: {new Date().toJSON().slice(0, 10)}</td>
            </tr>
            <tr className="style1">
                <td>{customerRecord[0].city.trim()}{", " + customerRecord[0].state + " " + customerRecord[0].zip.substring(0,5) + "-" + customerRecord[0].zip.substring(5,10)}</td>
                <td>Requested By: {user}</td>
            </tr>
            <tr className="style1">
                <td>{"(" + customerRecord[0].phone.substring(0,3) + ") " + customerRecord[0].phone.substring(3,6) + "-" +  customerRecord[0].phone.substring(6,10)}</td>
                <td>&nbsp;</td>
            </tr>
            </table>
            <hr></hr>
            <div align="center" class="style4">Current Payment Experience</div>
            <div align="center">
            <pre class="style1">-------------Account Status-------------

            </pre>
            </div>
            <PaymentExperienceTable props={props}></PaymentExperienceTable>
            <div style={{height: "20px"}}></div>
            <NSFNote></NSFNote>
            <PFCNote></PFCNote>
            
            <hr></hr>
            <div align="center" class="style4">Current Payment Experience Summary</div>
            <div align="center">
                <pre class="style1">-------------Account Status-------------
                </pre>
            </div>
            <SummaryTable props={props}></SummaryTable>
            

            <hr></hr>
            <div align="center" class="style4">Payment Trends</div>
            <div align="center">
                <pre class="style1">-------------Account Status-------------
                </pre>
            </div>
            <TrendTable props={props}></TrendTable>

            <hr></hr>
            <div align="center" class="style4">Current Scores</div>
            <ScoreTable props={props}></ScoreTable>

            <hr></hr>
            <div align="center" class="style4">Score History</div>
            <div style={{height: "20px"}}></div>
            <HistoryTable props={props}></HistoryTable>
            <hr></hr>
            <CustomerGraph accountId={props.accountId} />
            <PlacedForCollections accountId={props.accountId} />
            <NSF accountId={props.accountId} />
            

        </div>

    )
}