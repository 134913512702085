import React,{ useState, useEffect} from "react";
import { Container, Button, Card, CardHeader, Row, CardBody } from "shards-react";
import JobProcessService from "../../../flux/services/JobProcessService";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../../utils/tableCustomStyles";

 
export default function JobProcess (props)
{
  //const [view, setView] = useContext(useViewContext());
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getFileProcess()
    {

      try {
        // set loading to true before calling API
        const data = await JobProcessService.GetFileProcess();
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
    getFileProcess();
  
    }, []);
  
  const columns = [
      {
        id: data => data.file_upload_id,
        name: 'Process Id',
        selector: data => data.file_upload_id,
        sortable: true,
        width: '8%'
      },
      {
        name: 'File Name',
        selector: data => data.file_name,
        cell: data => (<div class="w-90 text-end text-nowrap">{data.file_name}</div>),
        sortable: true
      },
      {
        name: 'Status',
        sortable: true,
        selector: data => data.status
      },
      {
        name: 'Error',
        selector: data => data.error_message
      },
      {
        name: 'Start Time',
        selector: data => data.start_time,
        cell: data =>(<div class="w-90 text-end text-nowrap">{(new Date(data.start_time)).toLocaleString()}</div>) ,
        sortable: true
      },
      {
        id: data => data.end_time,
        name: 'End Time',
        selector: data => data.end_time,
        cell: data => (<div class="w-90 text-end text-nowrap">{(new Date(data.end_time)).toLocaleString()}</div>),
        sortable: true
      },{
        name: 'Rows Processed',
        selector: data => data.records_processed
      },{
        name: 'Rows Skipped',
        selector: data => data.records_skipped
      },
      {
        id: data => data.job_process_id,
        name: '',
        cell: data => (<button type="button" id={data.file_upload_id} class="btn btn-link" onClick={DeleteFile.bind(this)}>Delete</button>),
      }
  ]

  async function DeleteFile(e)
  {

    let isDelete = await JobProcessService.DeleteFile(e.target.id)
    if (isDelete === 0)
      alert("File Successfully Deleted")

    const data = await JobProcessService.GetFileProcess();
        setData(data);

  
  }
 
  if (!data) return (
    <span>Data not available</span>
  );
  //alert(JSON.stringify(props));
  return (
    

      <Container>
        <Card>
      <CardHeader className="border-bottom">
        <h5>
          Job Processes
        </h5>
      </CardHeader>
      <Row className="px-2">
        <CardBody>
        <DataTable customStyles={tableCustomStyles} columns={columns}
            data={data}
            ></DataTable>
        </CardBody>
        </Row>
        </Card>
      </Container>

  )
}

