import supabase from "../stores/supabase";

class PaymentExperienceService {

  
  async getPaymentExperience(accountId ) {
  
    let { data: credit_info, error } = await supabase
        .from('vw_credit_info')
        .select('credit_info_id, member_id,load_date_id,date_last_sale_id, extract_date_id, terms, recent_high_credit, account_balance, current_balance, one_day_past_due_amt, \
                    thirty_day_past_due_amt, sixty_day_past_due_amt, ninety_day_past_due_amt, number_days_slow, payment_history, pct_current, \
                    pct_one_day_past_due, pct_thirty_day_past_due, pct_sixty_day_past_due, pct_ninety_day_past_due')
        .eq('cci_account_id', accountId) 
        .order('member_id_order')                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
        ;
    //alert(JSON.stringify(credit_info));
    if (error != undefined)
      return null;
    if (credit_info == null)
      return null;

      return credit_info;


  }


}


export default new PaymentExperienceService()
